import { Game } from "./game";
import { USA } from "./usa";

export class UNI {
  static load(cb: Function) {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    Game.puzzle.year = year;
    Game.puzzle.month = month;
    Game.puzzle.day = day;

    let month_str = month.toString();
    if (month_str.length < 2) {
      month_str = "0" + month_str;
    }
    let day_str = day.toString();
    if (day_str.length < 2) {
      day_str = "0" + day_str;
    }
    const date_id = "" + year + "-" + month_str + "-" + day_str;

    Game.puzzle.id = "uni" + date_id;
    Game.puzzle.date_string = date_id;
    const base = "https://us-central1-puzz-242820.cloudfunctions.net/cors?url="
    const cors = "https://embed.universaluclick.com/c/stl/l/U2FsdGVkX1%2Bl6ljVqJRAl8cTAUiKxC1sL1FtawQY3zrGjUTVQJ01vXOIrxP9VwLH%0ACKzKlZiVvmzhV7zQ5t6TiMPr8u5wr1ajfurzXvSgJdInH8vW6yV3u0parfUwSX7b/g/fcx/d/" + date_id + "/data.json";
    const url = encodeURI(base + cors);

    let request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.onload = function () {
      if (request.status >= 200 && request.status < 400) {
        const json = JSON.parse(request.responseText);
        USA.parse(json);
        cb();
      } else {
        console.log(request.status, request);
      }
    };
    request.onerror = function () {
      console.log(request.status, request);
    };
    request.send();
  }
}