import { Game } from "./game"
import { Router } from "./router";

window.addEventListener("load", function () {

  const router = new Router();
  router.add("/", function () {
    Game.start("lat");
  }, true);
  router.add("/lat", function () {
    Game.start("lat");
  });
  router.add("/wsj", function () {
    Game.start("wsj");
  });
  router.add("/usa", function () {
    Game.start("usa");
  });
  router.add("/uni", function () {
    Game.start("uni");
  });
  router.add("/tny", function () {
    Game.start("tny");
  });

  window.addEventListener('popstate', function () {
    router.match();
  });

  router.match()

})