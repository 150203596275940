export interface CORSCallback {
  (data: string): void;
}

export class CORS {

  static load(url: string, cb: CORSCallback) {
    const base = "https://cors-wwe2cntfea-uc.a.run.app/";
    const req = new XMLHttpRequest();

    req.onload = function () {
      if (req.status >= 200 && req.status < 400) {
        cb(req.responseText);
      } else {
        console.log(req.status, req);
      }
    }

    req.onerror = function () {
      console.log(req.status, req);
    };

    console.log("load: ", url);
    req.open("POST", base, true);
    req.setRequestHeader("Content-Type", "application/json");
    req.send(JSON.stringify({ "url": url }));
  }
}