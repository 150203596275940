interface Route {
  regex?: RegExp;
  path: string;
  keys: string[];
  func: Function;
}

export class Router {
  routes: Route[] = [];
  default_route: Route | undefined;
  before_match: Function | undefined;

  add(path: string, callback: Function, is_default: boolean = false) {
    const r: Route = {
      path: path,
      regex: undefined,
      keys: [],
      func: callback,
    };
    const trim_path = this.trim_leading_slash(path);
    const parts = trim_path.split("/");
    if (parts.length > 1) {
      const re_parts: string[] = [];
      parts.forEach(p => {
        if (p.indexOf(":") !== -1) {
          r.keys.push(p.replace(":", ""));
          re_parts.push(".+");
        } else {
          r.keys.push("");
          re_parts.push(p);
        }
      });
      r.regex = new RegExp(re_parts.join("/"), "i");
    }
    this.routes.push(r);
    if (is_default) {
      this.default_route = r;
    }
    this.routes.sort(function (a, b) {
      return b.path.length - a.path.length;
    });
  }

  match() {
    if (this.before_match !== undefined) {
      this.before_match();
    }
    let path = window.location.pathname;
    if (path.charAt(path.length - 1) === "/") {
      path = path.slice(0, -1);
    }
    let matched = false;
    this.routes.some(route => {
      let route_match = false;
      if (route.regex) {
        route_match = route.regex.test(path);
      } else {
        route_match = route.path === path;
      }
      if (route_match) {
        matched = true;
        const args: string[] = [];
        if (route.keys.length) {
          const trim_path = this.trim_leading_slash(path);
          const parts = trim_path.split("/");
          for (let i = 0; i < parts.length; i++) {
            const p = parts[i];
            if (route.keys[i] !== "") {
              args.push(p);
            }
          }
        }
        route.func.call(this, ...args);
        return true;
      }
    });
    if (!matched && this.default_route !== undefined) {
      this.default_route.func.call(this, path);
    }
  }

  private trim_leading_slash(p: string): string {
    let t = p;
    if (t.charAt(0) === "/") {
      t = t.substr(1);
    }
    return t;
  }
}