import { WSJ } from "./wsj";
import { LAT } from "./lat";
import { USA } from "./usa";
import { UNI } from "./uni";
import { TNY } from "./tny";

export interface PuzzleCell {
  x: number;
  y: number;
  type: string;
  solution: string;
  guess: string;
  number: number | null;
  show_error: boolean;
}

export interface PuzzleWord {
  length: number;
  x: number;
  y: number;
  id: number;
  idx: number;
}

export interface PuzzleClue {
  word_id: number;
  text: string;
  number: number;
}

interface Puzzle {
  id: string;
  width: number;
  height: number;
  cells: PuzzleCell[];
  across_words: PuzzleWord[];
  down_words: PuzzleWord[];
  across_clues: PuzzleClue[];
  down_clues: PuzzleClue[];
  year: number;
  month: number;
  day: number;
  date_string: string;
  title: string;
  creator: string;
  valid: boolean;
  loading: boolean;
}

interface PuzzleInput {
  selected_row: number;
  selected_col: number;
  selected_word: PuzzleWord | null;
  across: boolean;
  elapsed_seconds: number;
  cells: PuzzleCell[];
  completed_at: Date | null;
}

export class Game {

  static DB_NAME = 'grid-puzzles';
  static DB_VERSION = 1;
  static DB_STORE_NAME = 'puzzles';
  static db: IDBDatabase;
  static timer = 0;
  static inactivity_timer = 0;
  static inactivity_duration = 60000;
  static publisher = "lat";

  static debug = true;

  static puzzle: Puzzle = {
    id: "",
    width: 0,
    height: 0,
    cells: [],
    across_words: [],
    down_words: [],
    across_clues: [],
    down_clues: [],
    year: 0,
    month: 0,
    day: 0,
    date_string: "",
    title: "",
    creator: "&nbsp;",
    valid: false,
    loading: true,
  };

  static input: PuzzleInput = {
    selected_row: 1,
    selected_col: 1,
    selected_word: null,
    across: true,
    elapsed_seconds: 0,
    cells: [],
    completed_at: null,
  };

  static characters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
  static days_of_week = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  static months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  // DB

  static open_db(cb: Function) {
    console.log("open_db ...");
    var req = indexedDB.open(Game.DB_NAME, Game.DB_VERSION);
    req.onsuccess = function () {
      Game.db = this.result;
      console.log("open_db DONE");
      cb();
    };

    req.onerror = function (e) {
      console.error("open_db:", e);
    };

    req.onupgradeneeded = function () {
      console.log("open_db.onupgradeneeded");
      req.result.createObjectStore(
        Game.DB_STORE_NAME
      );
    };
  }

  // mode either "readonly" or "readwrite"
  static get_object_store(store_name: string, mode: "readonly" | "readwrite") {
    var tx = Game.db.transaction(store_name, mode);
    return tx.objectStore(store_name);
  }

  static load_input(cb: Function) {
    let store = Game.get_object_store(Game.DB_STORE_NAME, "readonly");
    var req = store.get(Game.puzzle.id);
    req.onsuccess = function () {
      cb(req.result);
    };
    req.onerror = function () {
      console.error("Get error", this.error);
    };
  }

  static save() {
    Game.load_input(function (d: any) {
      let store = Game.get_object_store(Game.DB_STORE_NAME, "readwrite");
      if (d !== undefined) {
        store.put(Game.input, Game.puzzle.id);
      } else {
        store.add(Game.input, Game.puzzle.id);
      }
    });

    /*ck_get_game(puzzle.puzzle_id, function (d) {
      if (d !== undefined && d.recordChangeTag !== undefined) {
        input.recordChangeTag = d.recordChangeTag;
      }
      ck_save_game(input, puzzle.puzzle_id);
    });*/
  }

  // TIMER

  static show_elapsed_time() {
    let timer_div = document.getElementById("timer");
    let hours = Math.floor(Game.input.elapsed_seconds / 3600);
    let minutes = Math.floor(Game.input.elapsed_seconds / 60);
    minutes = minutes - (hours * 60);
    let minutes_str = String(minutes);
    if (hours > 0 && minutes_str.length == 1) {
      minutes_str = "0" + minutes;
    }
    let seconds = String(Game.input.elapsed_seconds % 60);
    if (seconds.length == 1) {
      seconds = "0" + seconds;
    }
    let hours_str = hours > 0 ? hours : "";
    let hour_sep = hours > 0 ? ":" : "";
    if (timer_div) {
      timer_div.innerHTML = hours_str + hour_sep + minutes + ":" + seconds;
    }
  }

  static increment_time() {
    Game.input.elapsed_seconds += 1;
    Game.show_elapsed_time();
  }

  static pause() {
    window.clearInterval(Game.timer);
    Game.timer = 0;
    window.clearTimeout(Game.inactivity_timer);
    Game.inactivity_timer = 0;

    let panel = document.createElement("div");
    panel.classList.add("panel");
    panel.style.marginTop = ((window.innerHeight - 300) / 2) + "px";
    let title = document.createElement("h5");
    title.innerHTML = "Game paused";
    panel.appendChild(title);
    let button = document.createElement("div");
    button.classList.add("button");
    button.innerHTML = "Resume"
    panel.appendChild(button);
    button.onclick = function () {
      Game.unpause();
    }

    Game.show_overlay(panel, function () {
      Game.unpause();
    })
  }

  static unpause() {
    if (!Game.puzzle.valid || Game.timer != 0 || Game.input.completed_at !== null) {
      return;
    }
    Game.timer = window.setInterval(Game.increment_time, 1000);
    Game.hide_overlay();
    Game.start_inactivity_timer();
  }

  static start_inactivity_timer() {
    window.clearTimeout(Game.inactivity_timer);
    Game.inactivity_timer = window.setTimeout(Game.pause, Game.inactivity_duration);
  }

  static hide_overlay() {
    let container = document.getElementById("container");
    if (container) {
      container.classList.remove("blurred");
    }
    let overlay = document.getElementById("overlay");
    if (overlay) {
      overlay.style.display = "none";
    }
  }

  static show_overlay(panel: HTMLElement, close_cb: Function) {
    let container = document.getElementById("container");
    if (container) {
      container.classList.add("blurred");
    }
    let overlay = document.getElementById("overlay");
    if (!overlay) {
      return;
    }
    overlay.innerHTML = "";
    overlay.onclick = function () {
      close_cb();
    }
    overlay.appendChild(panel);
    overlay.style.display = "block";
  }

  // INTERACTIONS

  static check_puzzle() {
    for (let i = 0; i < Game.puzzle.cells.length; i++) {
      if (Game.puzzle.cells[i].type != "block" && Game.puzzle.cells[i].solution != Game.input.cells[i].guess) {
        return;
      }
    }
    console.log("success!");
    if (Game.input.completed_at === null) {
      Game.input.completed_at = new Date();
    }
    window.clearInterval(Game.timer);
    let panel = document.createElement("div");
    panel.classList.add("panel");
    panel.style.marginTop = ((window.innerHeight - 300) / 2) + "px";
    let title = document.createElement("h5");
    title.innerHTML = "You did it!";
    panel.appendChild(title);
    let button = document.createElement("div");
    button.classList.add("button");
    button.innerHTML = "Yay!"
    panel.appendChild(button);
    button.onclick = function () {
      Game.hide_overlay();
    }
    Game.show_overlay(panel, function () {
      Game.hide_overlay();
    });
  }

  static select_prev_word_with_empty_cells() {
    if (Game.all_cells_full()) {
      Game.select_prev_word();
      return;
    }
    Game.select_prev_word();
    while (Game.selected_word_is_full()) {
      Game.select_prev_word();
    }
  }

  static select_next_word_with_empty_cells() {
    if (Game.all_cells_full()) {
      Game.select_next_word();
      return;
    }
    Game.select_next_word();
    while (Game.selected_word_is_full()) {
      Game.select_next_word();
    }
  }

  static all_cells_full(): boolean {
    for (const w of Game.puzzle.across_words) {
      for (let i = 0; i < w.length; i++) {
        let idx = ((w.y - 1) * Game.puzzle.width) + w.x + i - 1;
        const cell = Game.input.cells[idx];
        if (cell.guess === undefined || cell.guess.length == 0) {
          return false;
        }
      }
    }
    for (const w of Game.puzzle.down_words) {
      for (let i = 0; i < w.length; i++) {
        let idx = ((w.y + i - 1) * Game.puzzle.width) + w.x - 1;
        const cell = Game.input.cells[idx];
        if (cell.guess === undefined || cell.guess.length == 0) {
          return false;
        }
      }
    }
    return true;
  }

  static select_prev_word() {
    if (Game.input.selected_word === null) {
      return;
    }
    if (Game.input.across) {
      if (Game.input.selected_word.idx - 1 >= 0) {
        Game.input.selected_word = Game.puzzle.across_words[Game.input.selected_word.idx - 1];
      } else {
        Game.input.across = false;
        Game.input.selected_word = Game.puzzle.down_words[Game.puzzle.down_words.length - 1];
      }
    } else {
      if (Game.input.selected_word.idx - 1 >= 0) {
        Game.input.selected_word = Game.puzzle.down_words[Game.input.selected_word.idx - 1];
      } else {
        Game.input.across = true;
        Game.input.selected_word = Game.puzzle.across_words[Game.puzzle.across_words.length - 1];
      }
    }
    Game.select_empty_cell_in_word();
    Game.set_selections();
  }

  static select_next_word() {
    if (Game.input.selected_word === null) {
      return;
    }
    if (Game.input.across) {
      if (Game.input.selected_word.idx + 1 < Game.puzzle.across_words.length) {
        Game.input.selected_word = Game.puzzle.across_words[Game.input.selected_word.idx + 1];
      } else {
        Game.input.across = false;
        Game.input.selected_word = Game.puzzle.down_words[0];
      }
    } else {
      if (Game.input.selected_word.idx + 1 < Game.puzzle.down_words.length) {
        Game.input.selected_word = Game.puzzle.down_words[Game.input.selected_word.idx + 1];
      } else {
        Game.input.across = true;
        Game.input.selected_word = Game.puzzle.across_words[0];
      }
    }
    Game.select_empty_cell_in_word();
    Game.set_selections();
  }

  static selected_word_is_full(): boolean {
    if (Game.input.selected_word === null) {
      return true;
    }
    for (let i = 0; i < Game.input.selected_word.length; i++) {
      let idx = 0;
      if (Game.input.across) {
        idx = ((Game.input.selected_word.y - 1) * Game.puzzle.width) + Game.input.selected_word.x + i - 1;
      } else {
        idx = ((Game.input.selected_word.y + i - 1) * Game.puzzle.width) + Game.input.selected_word.x - 1;
      }
      const cell = Game.input.cells[idx];
      if (cell.guess === undefined || cell.guess.length == 0) {
        return false;
      }
    }
    return true;
  }

  static select_empty_cell_in_word() {
    if (Game.input.selected_word === null) {
      return;
    }
    Game.input.selected_col = Game.input.selected_word.x;
    Game.input.selected_row = Game.input.selected_word.y;
    if (Game.input.across) {
      for (let i = 0; i < Game.input.selected_word.length; i++) {
        let idx = ((Game.input.selected_word.y - 1) * Game.puzzle.width) + Game.input.selected_word.x + i - 1;
        if (Game.input.cells[idx].guess === undefined || Game.input.cells[idx].guess.length == 0) {
          Game.input.selected_col = Game.input.selected_word.x + i;
          break;
        }
      }
    } else {
      for (let i = 0; i < Game.input.selected_word.length; i++) {
        let idx = ((Game.input.selected_word.y + i - 1) * Game.puzzle.width) + Game.input.selected_word.x - 1;
        if (Game.input.cells[idx].guess === undefined || Game.input.cells[idx].guess.length == 0) {
          Game.input.selected_row = Game.input.selected_word.y + i;
          break;
        }
      }
    }
  }

  static select_next_cell() {
    if (Game.input.selected_word === null) {
      return;
    }
    if (Game.input.across) {
      if (Game.input.selected_word.x + Game.input.selected_word.length - 1 > Game.input.selected_col) {
        Game.input.selected_col = Game.input.selected_col + 1;
        Game.set_selections();
      } else {
        Game.select_next_word_with_empty_cells();
      }
    } else {
      if (Game.input.selected_word.y + Game.input.selected_word.length - 1 > Game.input.selected_row) {
        Game.input.selected_row = Game.input.selected_row + 1;
        Game.set_selections();
      } else {
        Game.select_next_word_with_empty_cells();
      }
    }
  }

  static select_prev_cell() {
    if (Game.input.selected_word === null) {
      return;
    }
    if (Game.input.across) {
      if (Game.input.selected_word.x < Game.input.selected_col) {
        Game.input.selected_col = Game.input.selected_col - 1;
        Game.set_selections();
      }
    } else {
      if (Game.input.selected_word.y < Game.input.selected_row) {
        Game.input.selected_row = Game.input.selected_row - 1;
        Game.set_selections();
      }
    }
  }

  static enter_text(character: string) {
    if (Game.debug) {
      console.log("enter_text: ", character, Game.input.selected_row);
    }
    let idx = ((Game.input.selected_row - 1) * Game.puzzle.width) + Game.input.selected_col - 1;
    let cell = Game.input.cells[idx];
    let table = document.getElementsByClassName("game")[0];
    let rows = table.getElementsByTagName("tr");
    let td = rows[Game.input.selected_row - 1].getElementsByTagName("td")[Game.input.selected_col - 1];
    let span = td.getElementsByTagName("span")[0];
    span.innerHTML = character;
    span.classList.remove("error");
    cell.guess = character;
    if (character.length > 0) {
      Game.select_next_cell();
    }
    Game.save();
    Game.check_puzzle();
  }

  static delete_text() {
    let idx = ((Game.input.selected_row - 1) * Game.puzzle.width) + Game.input.selected_col - 1;
    let cell = Game.input.cells[idx];
    if (cell.guess && cell.guess.length > 0) {
      Game.enter_text("");
    } else {
      Game.select_prev_cell();
      Game.enter_text("");
    }
  }

  static setup_document_listeners() {
    document.onclick = function () {
      if (Game.input.completed_at === null) {
        window.clearTimeout(Game.inactivity_timer);
        Game.inactivity_timer = 0;
      }
    }

    document.addEventListener('visibilitychange', function () {
      if (Game.input.completed_at === null) {
        if (document.visibilityState != "visible") {
          Game.pause();
        }
      }
    });
  };

  static selected_word(across: boolean): PuzzleWord | null {
    if (across) {
      for (let i = 0; i < Game.puzzle.across_words.length; i++) {
        let w = Game.puzzle.across_words[i];
        if (w.y == Game.input.selected_row && w.x <= Game.input.selected_col && (w.x + w.length) >= Game.input.selected_col) {
          return w;
        }
      }
    } else {
      for (let i = 0; i < Game.puzzle.down_words.length; i++) {
        let w = Game.puzzle.down_words[i];
        if (w.x == Game.input.selected_col && w.y <= Game.input.selected_row && (w.y + w.length) >= Game.input.selected_row) {
          return w;
        }
      }
    }
    return null
  }

  static update_guesses() {
    let table = document.getElementsByClassName("grid")[0];
    if (table !== undefined) {
      let rows = table.getElementsByTagName("tr");
      for (let j = 0; j < rows.length; j++) {
        let row = rows[j].getElementsByTagName("td");
        for (let i = 0; i < row.length; i++) {
          let idx = (j * Game.puzzle.width) + i;
          let cell = Game.puzzle.cells[idx];
          if (cell.type != "block" && Game.input.cells[idx].guess !== undefined && Game.input.cells[idx].guess.length > 0) {
            let td = row[i];
            let span = td.getElementsByTagName("span")[0];
            span.innerHTML = Game.input.cells[idx].guess;
          }
        }
      }
    }
  }

  // SCROLL

  static scroll() {
    let ids = ["across_scroll", "down_scroll"];
    let animate = false;
    for (let i = 0; i < ids.length; i++) {
      let id = ids[i];
      let el = document.getElementById(id);
      if (!el) {
        continue;
      }
      let target = Number(el.getAttribute("scroll_target"));
      let step = Number(el.getAttribute("scroll_step"));;
      if (el.scrollTop < target) {
        if (el.scrollTop + step > target) {
          el.scrollTop = target;
        } else {
          el.scrollTop = el.scrollTop + step;
          // check if there is more room to scroll
          if (el.scrollTop < (el.scrollHeight - el.offsetHeight)) {
            animate = true;
          }
        }
      } else if (el.scrollTop > target) {
        if (el.scrollTop - step < target) {
          el.scrollTop = target;
        } else {
          el.scrollTop = el.scrollTop - step;
          animate = true;
        }
      }
    }

    if (animate) {
      requestAnimationFrame(Game.scroll);
    }
  }

  static scroll_to_selected() {
    let title_height = 38;
    let across = document.getElementById("across_scroll");
    let down = document.getElementById("down_scroll");
    if (!across || !down) {
      return;
    }
    let across_sel = across.getElementsByClassName("selected");
    if (across_sel.length == 0) {
      across_sel = across.getElementsByClassName("light_selected");
    }
    let scroll_steps = 8;
    if (across_sel.length > 0) {
      let el = across_sel[0] as HTMLElement;
      let target = el.offsetTop - title_height;
      across.setAttribute("scroll_target", target.toString());
      across.setAttribute("scroll_step", (Math.abs(across.scrollTop - target) / scroll_steps).toString());
    }
    let down_sel = down.getElementsByClassName("selected");
    if (down_sel.length == 0) {
      down_sel = down.getElementsByClassName("light_selected");
    }
    if (down_sel.length > 0) {
      let el = down_sel[0] as HTMLElement;
      let target = el.offsetTop - title_height;
      down.setAttribute("scroll_target", target.toString());
      down.setAttribute("scroll_step", (Math.abs(down.scrollTop - target) / scroll_steps).toString());
    }
    requestAnimationFrame(Game.scroll);
  }

  static set_selections() {
    let table = document.getElementsByClassName("game")[0];
    let rows = table.getElementsByTagName("tr");
    let tds = table.getElementsByTagName("td");

    for (let i = 0; i < tds.length; i++) {
      tds[i].classList.remove("selected");
      tds[i].classList.remove("light_selected");
    }

    for (let j = 1; j <= Game.puzzle.height; j++) {
      let row = rows[j - 1];
      let tds = row.getElementsByTagName("td");
      let word = Game.input.selected_word;
      if (word) {
        for (let i = 1; i <= Game.puzzle.width; i++) {
          let td = tds[i - 1];

          if (Game.input.across) {
            if (j == word.y && (i >= word.x && i < (word.x + word.length))) {
              td.classList.add("light_selected");
            }
          } else {
            if (i == word.x && (j >= word.y && j < (word.y + word.length))) {
              td.classList.add("light_selected");
            }
          }
          if (Game.input.selected_col == i && Game.input.selected_row == j) {
            td.classList.add("selected");
          }
        }
      }
    }

    let clues = document.getElementsByClassName("clue");
    let across = document.getElementById("across_scroll");
    let down = document.getElementById("down_scroll");
    if (!across || !down) {
      return;
    }

    for (let i = 0; i < clues.length; i++) {
      clues[i].classList.remove("selected");
      clues[i].classList.remove("light_selected");
    }

    for (let i = 0; i < Game.puzzle.across_clues.length; i++) {
      let d = across.childNodes[i] as HTMLElement;
      let clue = Game.puzzle.across_clues[i];
      if (Game.input.across) {
        if (Game.input.selected_word && clue.word_id == Game.input.selected_word.id) {
          d.classList.add("selected");
        }
      } else {
        let word = Game.selected_word(true);
        if (word && clue.word_id == word.id) {
          d.classList.add("light_selected");
        }
      }
    }

    for (let i = 0; i < Game.puzzle.down_clues.length; i++) {
      let d = down.childNodes[i] as HTMLElement;
      let clue = Game.puzzle.down_clues[i];
      if (Game.input.across) {
        let word = Game.selected_word(false);
        if (word && clue.word_id == word.id) {
          d.classList.add("light_selected");
        }
      } else {
        if (Game.input.selected_word && clue.word_id == Game.input.selected_word.id) {
          d.classList.add("selected");
        }
      }
    }

    Game.scroll_to_selected();
  }

  // RENDER

  static title_h4(text: string) {
    let t = document.createElement("h4");
    t.innerHTML = text;
    return t;
  }

  static dots(): SVGSVGElement {
    const w = 20;
    const h = 20;
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute("viewBox", "0 0 " + w + " " + h);
    svg.setAttribute("width", w.toString());
    svg.setAttribute("height", h.toString());
    const c1 = document.createElementNS("http://www.w3.org/2000/svg", "circle");
    c1.setAttribute("cx", "5");
    c1.setAttribute("cy", "10");
    c1.setAttribute("r", "1.5");
    const c2 = document.createElementNS("http://www.w3.org/2000/svg", "circle");
    c2.setAttribute("cx", "10");
    c2.setAttribute("cy", "10");
    c2.setAttribute("r", "1.5");
    const c3 = document.createElementNS("http://www.w3.org/2000/svg", "circle");
    c3.setAttribute("cx", "15");
    c3.setAttribute("cy", "10");
    c3.setAttribute("r", "1.5");
    svg.appendChild(c1);
    svg.appendChild(c2);
    svg.appendChild(c3);
    return svg;
  }

  static render() {
    console.log(Game.puzzle);
    let header = document.getElementById("header");
    let content = document.getElementById("content");
    let h3 = document.getElementsByTagName("h3")[0];
    if (!header || !content) {
      return;
    }

    header.innerHTML = "";
    h3.innerHTML = "";
    content.innerHTML = "";
    if (Game.puzzle.loading) {
      content.classList.add("loading");
    } else {
      content.classList.remove("loading");
    }

    let print_link = document.createElement("a");
    print_link.classList.add("button");
    print_link.classList.add("print_link");
    var link = "/print.html";
    if (location.search) {
      link = link + location.search;
    }
    print_link.href = link;
    print_link.target = "new";
    print_link.innerHTML = "Print";
    header.appendChild(print_link);

    const selector = document.createElement("div");
    selector.classList.add("selector");
    const lat_link = document.createElement("a");
    lat_link.innerText = "LAT";
    const wsj_link = document.createElement("a");
    wsj_link.innerText = "WSJ";
    const usa_link = document.createElement("a");
    usa_link.innerText = "USA";
    const tny_link = document.createElement("a");
    tny_link.innerText = "TNY";
    const uni_link = document.createElement("a");
    uni_link.innerText = "UNI";
    selector.appendChild(lat_link);
    // disable WSJ
    //selector.appendChild(wsj_link);
    selector.appendChild(usa_link);
    selector.appendChild(tny_link);

    // disable UNI link
    //selector.appendChild(uni_link);
    header.appendChild(selector);

    lat_link.onclick = function () {
      window.location.href = "/"
    }
    wsj_link.onclick = function () {
      window.location.href = "/wsj";
    }
    usa_link.onclick = function () {
      window.location.href = "/usa";
    }
    tny_link.onclick = function () {
      window.location.href = "/tny";
    }
    uni_link.onclick = function () {
      window.location.href = "/uni";
    }

    if (Game.publisher === "lat") {
      lat_link.classList.add("selected");
    } else if (Game.publisher === "wsj") {
      wsj_link.classList.add("selected");
    } else if (Game.publisher === "usa") {
      usa_link.classList.add("selected");
    } else if (Game.publisher === "uni") {
      uni_link.classList.add("selected");
    } else {
      tny_link.classList.add("selected");
    }

    let date = new Date(Game.puzzle.year, Game.puzzle.month - 1, Game.puzzle.day);
    let day_of_week = Game.days_of_week[date.getDay()];
    h3.innerHTML = day_of_week;
    let date_str = " " + Game.months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
    let span = document.createElement("span");
    span.innerHTML = date_str;
    h3.appendChild(span);
    let info = document.createElement("div");
    info.classList.add("info");
    info.innerHTML = Game.puzzle.creator;
    h3.appendChild(info);
    document.title = day_of_week + date_str;

    let timer = document.createElement("div");
    timer.classList.add("timer");
    timer.id = "timer";
    timer.innerHTML = "0:00";
    h3.appendChild(timer);
    timer.onclick = function () {
      if (Game.input.completed_at === null) {
        Game.pause();
      }
    }

    let opts = document.createElement("div");
    opts.classList.add("opts");
    const dots = Game.dots();
    opts.appendChild(dots);
    h3.appendChild(opts);

    const contain = document.createElement("div");
    contain.tabIndex = -1;
    contain.classList.add("dropdown-options-wrapper");
    const menu_opts = document.createElement("div");
    menu_opts.classList.add("dropdown-options");
    const reveal = document.createElement("a");
    reveal.innerText = "Show Errors";
    menu_opts.appendChild(reveal);
    contain.appendChild(menu_opts);
    opts.appendChild(contain);

    opts.onclick = function () {
      if (contain.getAttribute("visible") === "true") {
        return;
      }
      contain.style.display = "block";
      contain.setAttribute("visible", "true");
      contain.focus();
    }

    contain.onblur = function () {
      contain.style.display = "none";
      window.setTimeout(function () {
        contain.removeAttribute("visible");
      }, 150);
    };
    contain.onclick = function (e) {
      e.stopPropagation();
      return false;
    }

    reveal.onclick = function () {
      contain.blur();
      for (let i = 0; i < Game.puzzle.cells.length; i++) {
        const cell = Game.puzzle.cells[i];
        const input_cell = Game.input.cells[i];
        if (cell.type === "block") {
          continue;
        }
        if (cell.solution != input_cell.guess && input_cell.guess !== "") {
          cell.show_error = true;
        } else {
          cell.show_error = false;
        }
      }
      Game.render();
    }

    let left = document.createElement("div");
    left.classList.add("left");
    content.appendChild(left);

    let right = document.createElement("div");
    right.classList.add("right");
    content.appendChild(right);

    let across = document.createElement("div");
    across.classList.add("column");
    right.appendChild(across);

    let down = document.createElement("div");
    down.classList.add("column");
    right.appendChild(down);

    let regular_cell_max = 225;

    // TODO: are these still needed
    //let width = 750;
    //let large = Game.puzzle.cells.length > regular_cell_max;

    // table
    let table = document.createElement("table");
    table.classList.add("game");
    if (Game.puzzle.cells.length > regular_cell_max) {
      table.classList.add("small");
    }
    let rows = [];
    for (let i = 0; i < Game.puzzle.height; i++) {
      let tr = document.createElement("tr");
      rows.push(tr);
    }
    const puzzle_width = 500;
    const cell_size = puzzle_width / Game.puzzle.width;
    for (let i = 1; i <= Game.puzzle.width; i++) {
      for (let j = 1; j <= Game.puzzle.height; j++) {
        let td = document.createElement("td");
        td.style.width = cell_size + "px";
        td.style.height = cell_size + "px";
        rows[j - 1].appendChild(td);
        let idx = ((j - 1) * Game.puzzle.width) + i - 1;
        var cell = Game.puzzle.cells[idx];
        var input_cell = Game.input.cells[idx];
        if (cell.type == "block") {
          td.classList.add("block");
        }
        if (cell.number !== null) {
          let d = document.createElement("div");
          d.innerHTML = cell.number.toString();
          td.appendChild(d);
        }
        let span = document.createElement("span");
        if (input_cell.guess !== undefined && input_cell.guess !== null) {
          span.innerHTML = input_cell.guess;
        }
        td.appendChild(span);
        if (cell.type != "block") {
          td.onclick = function () {
            // toggle direction
            if (Game.input.selected_col == i && Game.input.selected_row == j) {
              Game.input.across = !Game.input.across;
            }
            Game.input.selected_col = i;
            Game.input.selected_row = j;
            Game.input.selected_word = Game.selected_word(Game.input.across);
            Game.set_selections();
          }
        }
        if (cell.show_error) {
          span.classList.add("error");
        }
      }
    }

    for (let i = 0; i < rows.length; i++) {
      table.appendChild(rows[i]);
    }
    left.appendChild(table);

    if (!Game.puzzle.valid && !Game.puzzle.loading) {
      const empty = document.createElement("div");
      empty.classList.add("empty");
      empty.innerText = "No puzz today";
      table.appendChild(empty);
    }

    // clues
    across.appendChild(Game.title_h4("Across"));
    down.appendChild(Game.title_h4("Down"));
    let across_scroll = document.createElement("div");
    across_scroll.classList.add("scroll");
    across_scroll.id = "across_scroll";
    across.appendChild(across_scroll);
    let down_scroll = document.createElement("div");
    down_scroll.classList.add("scroll");
    down_scroll.id = "down_scroll";
    down.appendChild(down_scroll);

    for (let i = 0; i < Game.puzzle.across_clues.length; i++) {
      let d = document.createElement("div");
      d.classList.add("clue");
      let clue = Game.puzzle.across_clues[i];
      if (Game.input.across) {
        if (Game.input.selected_word && clue.word_id == Game.input.selected_word.id) {
          d.classList.add("selected");
        }
      } else {
        let word = Game.selected_word(true);
        if (word && clue.word_id == word.id) {
          d.classList.add("light_selected");
        }
      }
      let number = document.createElement("span");
      number.classList.add("number");
      number.innerHTML = clue.number.toString();
      d.appendChild(number);
      let text = document.createElement("span");
      text.classList.add("text");
      text.innerHTML = clue.text;
      d.appendChild(text);
      across_scroll.appendChild(d);
      d.onclick = function () {
        Game.input.across = true;
        for (let i = 0; i < Game.puzzle.across_words.length; i++) {
          let word = Game.puzzle.across_words[i];
          if (word.id == clue.word_id) {
            Game.input.selected_word = word;
            break;
          }
        }
        if (Game.input.selected_word) {
          Game.input.selected_row = Game.input.selected_word.y;
          Game.input.selected_col = Game.input.selected_word.x;
        }
        Game.set_selections();
      }
    }

    for (let i = 0; i < Game.puzzle.down_clues.length; i++) {
      let d = document.createElement("div");
      d.classList.add("clue");
      let clue = Game.puzzle.down_clues[i];

      let number = document.createElement("span");
      number.classList.add("number");
      number.innerHTML = Game.puzzle.down_clues[i].number.toString();
      d.appendChild(number);
      let text = document.createElement("span");
      text.classList.add("text");
      text.innerHTML = Game.puzzle.down_clues[i].text;
      d.appendChild(text);
      down_scroll.appendChild(d);
      d.onclick = function () {
        Game.input.across = false;
        for (let i = 0; i < Game.puzzle.down_words.length; i++) {
          let word = Game.puzzle.down_words[i];
          if (word.id == clue.word_id) {
            Game.input.selected_word = word;
            break;
          }
        }
        if (Game.input.selected_word) {
          Game.input.selected_row = Game.input.selected_word.y;
          Game.input.selected_col = Game.input.selected_word.x;
        }
        Game.set_selections();
      }
    }
    Game.set_selections();
    Game.show_elapsed_time();
    Game.unpause();
  }

  /*
  static show_logged_in_state(userIdentity) {
    let link = document.createElement("span");
    link.classList.add("link");
    let name = userIdentity.nameComponents ? userIdentity.nameComponents.givenName + " " : "iCloud ";
    link.innerHTML = name + "\u25BF";
    let info = document.getElementById("sign-in");
    info.innerHTML = "";
    info.tabIndex = -1;
    info.appendChild(link);

    let menu = document.createElement("div");
    menu.classList.add("menu");
    menu.style.display = "none";
    let sign_out_link = document.createElement("div");
    sign_out_link.classList.add("link");
    sign_out_link.innerHTML = "Sign out";
    menu.appendChild(sign_out_link);
    info.appendChild(menu);

    sign_out_link.onclick = function (e) {
      let b = document.getElementById("apple-sign-out-button").firstChild;
      b.click();
    }

    info.onclick = function (e) {
      if (menu.style.display == "none") {
        menu.style.display = "block";
      } else {
        menu.style.display = "none";
      }
    }

    info.onblur = function (e) {
      menu.style.display = "none";
    }

    ck_get_game(puzzle.puzzle_id, function (d) {
      if (d !== undefined) {
        if (d.fields.completedAt !== undefined) {
          input.completed_at = new Date(d.fields.completedAt.value);
        }
        if (d.fields.elapsedSeconds !== undefined) {
          input.elapsed_seconds = d.fields.elapsedSeconds.value;
        }
        if (d.fields.guesses !== undefined) {
          if (input.cells === undefined) {
            input.cells = [];
            for (let i = 0; i < d.fields.guesses.value.length; i++) {
              let cell = { guess: d.fields.guesses.value[i] };
              input.cells.push(cell);
            }
          } else {
            for (let i = 0; i < d.fields.guesses.value.length; i++) {
              let val = d.fields.guesses.value[i];
              if (val != "_") {
                input.cells[i].guess = d.fields.guesses.value[i];
              }
            }
          }
          input.elapsed_seconds = d.fields.elapsedSeconds.value;
        }
        update_guesses();
        show_elapsed_time();
      }
    });
  }

  function show_logged_out_state(error) {
    if (error !== undefined) {
      console.log(error);
    }
    let info = document.getElementById("sign-in");
    let span = document.createElement("span");
    span.classList.add("link");
    span.innerHTML = "Sign in";
    span.onclick = function (e) {
      let b = document.getElementById("apple-sign-in-button").firstChild;
      b.click();
    }
    info.innerHTML = "";
    info.appendChild(span);
  }*/

  static set_empty() {
    Game.puzzle.width = 1;
    Game.puzzle.height = 1;
    const cell: PuzzleCell = {
      x: 1,
      y: 1,
      type: "",
      solution: "",
      guess: "",
      number: null,
      show_error: false,
    }
    const input: PuzzleCell = {
      x: 1,
      y: 1,
      type: "",
      solution: "",
      guess: "",
      number: null,
      show_error: false,
    }
    Game.puzzle.cells.push(cell);
    Game.input.cells.push(input);
  }

  static load() {
    // loading state
    Game.set_empty();
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    Game.puzzle.year = year;
    Game.puzzle.month = month;
    Game.puzzle.day = day;
    Game.puzzle.loading = true;

    Game.render();

    // reset
    Game.puzzle.width = 0;
    Game.puzzle.height = 0;
    Game.puzzle.cells = [];
    Game.input.cells = [];

    const ready_cb = function () {
      Game.load_input(function (d: PuzzleInput) {
        if (Game.debug) {
          console.log("loading input: ", d);
        }
        if (d !== undefined) {
          Game.input = d;
        }
        Game.puzzle.loading = false;
        Game.render();
      });
    }

    if (Game.publisher === "lat") {
      LAT.load(function () {
        ready_cb();
      });
    } else if (Game.publisher === "wsj") {
      WSJ.load(function () {
        ready_cb();
      });
    } else if (Game.publisher === "usa") {
      USA.load(function () {
        ready_cb();
      });
    } else if (Game.publisher === "uni") {
      UNI.load(function () {
        ready_cb();
      });
    } else {
      TNY.load(function () {
        ready_cb();
      });
    }
  }

  static start(publisher: string) {
    Game.publisher = publisher;
    console.log("game start:", publisher);
    Game.open_db(function () {
      Game.load();
    });
    Game.setup_document_listeners();

    document.onkeydown = function (e) {
      if (Game.debug) {
        console.log("onkeydown: ", e);
      }
      window.clearTimeout(Game.inactivity_timer);
      Game.inactivity_timer = 0;
      if (e.keyCode == 9) { // tab
        if (e.shiftKey) {
          Game.select_prev_word_with_empty_cells();
        } else {
          Game.select_next_word_with_empty_cells();
        }
        e.preventDefault();
      }
      if (e.keyCode == 27) { // esc
        Game.unpause();
      }
      if (e.keyCode == 32) { // space
        Game.input.across = !Game.input.across;
        Game.input.selected_word = Game.selected_word(Game.input.across);
        Game.set_selections();
      }
      if (Game.input.completed_at !== null) {
        return;
      }
      if (e.keyCode == 8) {
        Game.delete_text();
        e.preventDefault();
      }
      if (e.keyCode >= 65 && e.keyCode <= 90 && !e.metaKey) {
        Game.enter_text(Game.characters[e.keyCode - 65]);
      }
    }
  }

}