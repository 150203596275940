import { AmuseLabs } from "./amuse_labs";
import { CORS } from "./cors";
import { Game } from "./game";

export class TNY {

  static load(cb: Function) {

    const now = new Date();
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    Game.puzzle.year = year;
    Game.puzzle.month = month;
    Game.puzzle.day = day;

    const month_name = Game.months[month - 1].toLowerCase();
    console.log(month_name);

    let month_str = month.toString();
    if (month_str.length < 2) {
      month_str = "0" + month_str;
    }
    let day_str = day.toString();
    if (day_str.length < 2) {
      day_str = "0" + day_str;
    }
    const date_id = "" + year + month_str + day_str;

    Game.puzzle.id = "tny" + date_id;
    Game.puzzle.date_string = date_id;

    // monday, wednesday, friday only
    const day_of_week = now.getDay();
    if (day_of_week % 2 === 0) {
      Game.puzzle.valid = false;
      Game.set_empty();
      cb();
      return;
    }

    const page = "https://www.newyorker.com/puzzles-and-games-dept/crossword/" + year + "/" + month_str + "/" + day_str;

    const t0 = performance.now();

    const page_cb = function (data: string) {
      console.log("page load");

      // links like https://cdn3.amuselabs.com/tny/crossword?id=139785bf&set=tny-weekly&embed=1&compact=1&maxCols=2
      const pattern = "https://cdn3.amuselabs.com/tny/crossword\?.+?&";
      const re = new RegExp(pattern);
      const matches = data.match(re);
      if (matches && matches.length > 0) {
        const match = matches[0];
        const parts = match.split("id=");
        const id = parts[1].replace("&", "");
        const url = "https://cdn3.amuselabs.com/tny/crossword?id=" + id + "&set=tny-weekly&embed=1&compact=1&maxCols=2";

        CORS.load(url, data_cb);
      } else {
        console.log("Can't find id");
      }
    }

    const data_cb = function (data: string) {
      console.log("data load");
      const pattern = "window.rawc = '.+?'";
      const re = new RegExp(pattern);
      const matches = data.match(re);
      if (matches && matches.length > 0) {
        const match = matches[0];
        let d = match.replace("window.rawc = '", "");
        d = d.replace("'", "");
        const j = AmuseLabs.decode(d);
        AmuseLabs.parse(j);
        cb();
      } else {
        console.log("Can't find window.rawc");
      }
      console.log("load: " + (performance.now() - t0) + " ms");
    }

    CORS.load(page, page_cb);
  }

}