import { Game, PuzzleCell, PuzzleClue, PuzzleWord } from "./game";

interface AmuseWord {
  word: string,
  nBoxes: number,
  x: number,
  y: number,
  acrossNotDown: boolean,
  clueSection: string,
  clueNum: 1,
  clue: {
    clue: string,
  },
}

interface AmuseJSON {
  author: string,
  title: string,
  h: number,
  w: number,
  box: string[][],
  placedWords: AmuseWord[];
  clueNums: number[][];
}

export class AmuseLabs {

  static toArray = function (e: string, t?: any) {
    for (var r, i, n, a = e.replace(/[^A-Za-z0-9\+\/]/g, ""), o = a.length, c = t ? Math.ceil((3 * o + 1 >> 2) / t) * t : 3 * o + 1 >> 2, s = new Uint8Array(c), l = 0, d = 0, u = 0; u < o; u++)
      if (i = 3 & u, l |= ((n = a.charCodeAt(u)) > 64 && n < 91 ? n - 65 : n > 96 && n < 123 ? n - 71 : n > 47 && n < 58 ? n + 4 : 43 === n ? 62 : 47 === n ? 63 : 0) << 18 - 6 * i, 3 === i || o - u == 1) {
        for (r = 0; r < 3 && d < c; r++, d++) s[d] = l >>> (16 >>> r & 24) & 255;
        l = 0
      } return s
  }

  static arrayToString = function (e: Uint8Array) {
    for (var t, r = "", i = e.length, n = 0; n < i; n++) t = e[n], String.fromCodePoint || function (e) {
      var t = function () {
        for (var t = [], r = 0, i = "", n = 0, a = arguments.length; n !== a; ++n) {
          var o = +arguments[n];
          if (!(o < 1114111 && o >>> 0 === o)) throw RangeError("Invalid code point: " + o);
          o <= 65535 ? r = t.push(o) : (o -= 65536, r = t.push(55296 + (o >> 10), o % 1024 + 56320)), r >= 16383 && (i += e.apply(null, t), t.length = 0)
        }
        return i + e.apply(null, t)
      };
      try {
        Object.defineProperty(String, "fromCodePoint", {
          value: t,
          configurable: !0,
          writable: !0
        })
      } catch (e) {
        String.fromCodePoint = t
      }
    }(String.fromCharCode), r += String.fromCodePoint(t > 251 && t < 254 && n + 5 < i ? 1073741824 * (t - 252) + (e[++n] - 128 << 24) + (e[++n] - 128 << 18) + (e[++n] - 128 << 12) + (e[++n] - 128 << 6) + e[++n] - 128 : t > 247 && t < 252 && n + 4 < i ? (t - 248 << 24) + (e[++n] - 128 << 18) + (e[++n] - 128 << 12) + (e[++n] - 128 << 6) + e[++n] - 128 : t > 239 && t < 248 && n + 3 < i ? (t - 240 << 18) + (e[++n] - 128 << 12) + (e[++n] - 128 << 6) + e[++n] - 128 : t > 223 && t < 240 && n + 2 < i ? (t - 224 << 12) + (e[++n] - 128 << 6) + e[++n] - 128 : t > 191 && t < 224 && n + 1 < i ? (t - 192 << 6) + e[++n] - 128 : t);
    return r
  }

  static n = function (e: string) {
    return JSON.parse(AmuseLabs.arrayToString(AmuseLabs.toArray(e)));
  }

  static decode(s: string) {
    var e = function (e: string[]) {
      for (var t = [], r = !1, i = e.length - 1; i >= 0; i--) {
        var n = e[i];
        if ("." == n) {
          r = !0;
          break
        }
        try {
          var a = parseInt(n, 16);
          t.push(a)
        } catch (t) {
          return new Error("unexpected charinkey: " + e), e.join("")
        }
      }
      if (!r) return new Error("mayday nosep " + e), e.join("");
      if (0 == t.length) return new Error("mayday badsep " + e), e.join("");
      for (var o = e.length - t.length - 2, c = 0, s = 0; c < o;) {
        var l = t[s];
        l += 2;
        for (var d = o - c + 1, u = c, h = c + (l = Math.min(l, d)) - 1; u < h; h--, u++) {
          var f = e[h];
          e[h] = e[u], e[u] = f
        }
        c += l, s = (s + 1) % t.length
      }
      return e.join("").substr(0, o + 1)
    };
    var pe = e(s.split(""));
    return JSON.parse(AmuseLabs.arrayToString(AmuseLabs.toArray(pe)));
  }

  static parse(json: AmuseJSON) {
    Game.puzzle.title = json.title;
    Game.puzzle.creator = json.author;
    Game.puzzle.width = json.w;
    Game.puzzle.height = json.h;

    const populate_input_cells = Game.input.cells.length === 0;
    const block = "\u0000";

    for (let i = 0; i < Game.puzzle.height; i++) {
      for (let j = 0; j < Game.puzzle.width; j++) {
        const el = json.box[j][i];
        const type = el === block ? "block" : "input";
        const n = json.clueNums[j][i];
        var num: number | null = null;
        if (n !== 0) {
          num = n;
        }
        let cell: PuzzleCell = {
          x: i,
          y: j,
          solution: el,
          type: type,
          number: num,
          guess: "",
          show_error: false,
        };
        let input_cell: PuzzleCell = {
          x: i,
          y: j,
          solution: el,
          type: type,
          number: num,
          guess: "",
          show_error: false,
        };
        const game_idx = (i * Game.puzzle.width) + j;
        Game.puzzle.cells[game_idx] = cell;
        if (populate_input_cells) {
          Game.input.cells.push(input_cell);
        }
      }
    }

    for (let i = 0; i < json.placedWords.length; i++) {
      const w = json.placedWords[i];
      let word: PuzzleWord = {
        length: w.nBoxes,
        x: w.x + 1,
        y: w.y + 1,
        id: w.clueNum,
        idx: 0,
      };

      let text = w.clue.clue;
      // must be a better way
      text = text.replace(/â/g, "\“");
      text = text.replace(/â/g, "\”");
      text = text.replace(/â/g, "\‘");
      text = text.replace(/â/g, "\’");
      text = text.replace(/Ã©/g, "é");
      text = text.replace(/Â­/g, "");
      text = text.replace(/Ã§/g, "ç");
      text = text.replace(/Â/g, "");
      text = text.replace(/Ã¶/g, "ö");
      text = text.replace(/Ã/g, "Ö");
      text = text.replace(/Ã/g, "à");
      let clue: PuzzleClue = {
        word_id: w.clueNum,
        text: text,
        number: w.clueNum,
      }
      if (w.acrossNotDown === true) {
        word.idx = Game.puzzle.across_words.length;
        Game.puzzle.across_words.push(word);
        Game.puzzle.across_clues.push(clue);
      } else {
        word.idx = Game.puzzle.down_words.length;
        Game.puzzle.down_words.push(word);
        Game.puzzle.down_clues.push(clue);
      }
    }

    Game.puzzle.valid = true;

    if (Game.input.selected_word === null) {
      Game.input.selected_word = Game.puzzle.across_words[0];
    }
  }
}