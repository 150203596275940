import { CORS } from "./cors";
import { Game, PuzzleCell, PuzzleWord, PuzzleClue } from "./game";

interface USAJSON {
  Layout: { [key: string]: string },
  Solution: { [key: string]: string },
  DownClue: string,
  AcrossClue: string,
  Date: string,
  Author: string,
  Editor: string,
  Title: string,
  Height: string,
  Width: string,
  Copyright: string,
  AllAnswer: string,
}

export class USA {

  static load(cb: Function) {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    Game.puzzle.year = year;
    Game.puzzle.month = month;
    Game.puzzle.day = day;

    let month_str = month.toString();
    if (month_str.length < 2) {
      month_str = "0" + month_str;
    }
    let day_str = day.toString();
    if (day_str.length < 2) {
      day_str = "0" + day_str;
    }
    const date_id = "" + year + "-" + month_str + "-" + day_str;

    //https://gamedata.services.amuniversal.com/c/uupuz/l/U2FsdGVkX18CR3EauHsCV8JgqcLh1ptpjBeQ%2Bnjkzhu8zNO00WYK6b%2BaiZHnKcAD%0A9vwtmWJp2uHE9XU1bRw2gA%3D%3D/g/usaon/d/2020-11-26/data.json

    Game.puzzle.id = "usa" + date_id;
    Game.puzzle.date_string = date_id;
    const url = "http://gamedata.services.amuniversal.com/c/uupuz/l/U2FsdGVkX18CR3EauHsCV8JgqcLh1ptpjBeQ%2Bnjkzhu8zNO00WYK6b%2BaiZHnKcAD%0A9vwtmWJp2uHE9XU1bRw2gA%3D%3D/g/usaon/d/" + date_id + "/data.json";

    CORS.load(url, function (d: string) {
      console.log(d);
      const json = JSON.parse(d);
      USA.parse(json);
      cb();
    })
  }

  static parse(json: USAJSON) {
    Game.puzzle.title = json.Title;
    Game.puzzle.creator = json.Author;
    Game.puzzle.width = Number(json.Width);
    Game.puzzle.height = Number(json.Height);

    const populate_input_cells = Game.input.cells.length === 0;
    const block = "-1";

    for (let i = 0; i < Game.puzzle.height; i++) {
      const line_key = "Line" + (i + 1);
      const line = json.Layout[line_key];
      for (let j = 0; j < Game.puzzle.width; j++) {
        const char_idx = j * 2;
        const el = line.substring(char_idx, char_idx + 2);
        const solution = json.Solution[line_key].substring(j, j + 1);
        const type = el === block ? "block" : "input";
        var num: number | null = null;
        if (type !== "block" && el != "00") {
          num = Number(el);
        }
        let cell: PuzzleCell = {
          x: i,
          y: j,
          solution: solution,
          type: type,
          number: num,
          guess: "",
        };
        let input_cell: PuzzleCell = {
          x: i,
          y: j,
          solution: solution,
          type: type,
          number: num,
          guess: "",
        };
        const game_idx = (i * Game.puzzle.width) + j;
        Game.puzzle.cells[game_idx] = cell;
        if (populate_input_cells) {
          Game.input.cells.push(input_cell);
        }
      }
    }

    // build letter grid
    let grid: string[][] = [];
    for (let i = 1; i <= Game.puzzle.height; i++) {
      const line_key = "Line" + i;
      const line: string[] = [];
      const sol = json.Solution[line_key];
      for (let j = 0; j < sol.length; j++) {
        const s = sol[j];
        line.push(s);
      }
      grid.push(line);
    }

    // calculate across words
    let ac_id = 1;
    let ac_w = "";
    let ac_x = 0;
    let ac_y = 0;
    for (let i = 0; i < Game.puzzle.height; i++) {
      let prev_el = "";
      for (let j = 0; j < Game.puzzle.width; j++) {
        let el = grid[i][j];
        if (el === " ") {
          prev_el = el;
          continue;
        }
        if (prev_el == "" || prev_el == " ") {
          if (ac_w.length > 0) {
            // save word
            let word: PuzzleWord = {
              length: ac_w.length,
              x: ac_x,
              y: ac_y,
              id: ac_id,
              idx: Game.puzzle.across_words.length,
            };
            Game.puzzle.across_words.push(word);
            ac_id++;
          }
          ac_x = j + 1;
          ac_y = i + 1;
          ac_w = "";
        }
        ac_w = ac_w + el;
        prev_el = el;
      }
    }
    // add last across
    if (ac_w.length > 0) {
      // save word
      let word: PuzzleWord = {
        length: ac_w.length,
        x: ac_x,
        y: ac_y,
        id: ac_id,
        idx: Game.puzzle.across_words.length,
      };
      Game.puzzle.across_words.push(word);
    }

    // calculate down words
    let dn_id = 1;
    let dn_w = "";
    let dn_x = 0;
    let dn_y = 0;
    for (let i = 0; i < Game.puzzle.width; i++) {
      let prev_el = "";
      for (let j = 0; j < Game.puzzle.height; j++) {
        let el = grid[j][i];
        if (el === " ") {
          prev_el = el;
          continue;
        }
        if (prev_el == "" || prev_el == " ") {
          if (dn_w.length > 0) {
            // save word
            let word: PuzzleWord = {
              length: dn_w.length,
              x: dn_x,
              y: dn_y,
              id: dn_id,
              idx: Game.puzzle.down_words.length,
            };
            Game.puzzle.down_words.push(word);
            dn_id++;
          }
          dn_x = i + 1;
          dn_y = j + 1;
          dn_w = "";
        }
        dn_w = dn_w + el;
        prev_el = el;
      }
    }
    // add last down
    if (dn_w.length > 0) {
      // save word
      let word: PuzzleWord = {
        length: dn_w.length,
        x: dn_x,
        y: dn_y,
        id: dn_id,
        idx: Game.puzzle.down_words.length,
      };
      Game.puzzle.down_words.push(word);
    }

    // down words are added col by col, sort into row by row
    Game.puzzle.down_words.sort(function (a: PuzzleWord, b: PuzzleWord) {
      if (a.y === b.y) {
        return a.x - b.x;
      }
      return a.y - b.y;
    })

    // reset down word idxs and ids for new sort
    for (let i = 0; i < Game.puzzle.down_words.length; i++) {
      Game.puzzle.down_words[i].idx = i;
      Game.puzzle.down_words[i].id = i + 1;
    }

    // reset down clue ids
    for (let i = 1; i <= Game.puzzle.down_clues.length; i++) {
      Game.puzzle.down_clues[i].word_id = i;
    }

    const across_clues = json.AcrossClue.split("\n");
    let id = 1;
    for (let i = 0; i < across_clues.length; i++) {
      const c = across_clues[i];
      const parts = c.split("|");
      const n = Number(parts[0]);
      let clue: PuzzleClue = {
        word_id: id,
        text: parts[1],
        number: n,
      };
      Game.puzzle.across_clues.push(clue);
      id++;
    }
    const down_clues = json.DownClue.split("\n");
    id = 1;
    for (let i = 0; i < down_clues.length; i++) {
      const c = down_clues[i];
      if (c.length === 0 || !c.includes("|")) {
        continue;
      }
      const parts = c.split("|");
      const n = Number(parts[0]);
      let clue: PuzzleClue = {
        word_id: id,
        text: parts[1],
        number: n,
      };
      Game.puzzle.down_clues.push(clue);
      id++;
    }
    Game.puzzle.valid = true;

    if (Game.input.selected_word === null) {
      Game.input.selected_word = Game.puzzle.across_words[0];
    }
  }

}