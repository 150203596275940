import { CORS } from "./cors";
import { Game, PuzzleCell, PuzzleWord, PuzzleClue } from "./game";

export class WSJ {

  static load(cb: Function) {

    const now = new Date();
    var date = new Date();
    // no wsj puzz on sunday
    if (now.getDay() === 0) { // sunday
      // back to saturday
      date.setDate(now.getDate() - 1);
    }

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    Game.puzzle.year = year;
    Game.puzzle.month = month;
    Game.puzzle.day = day;

    const month_name = Game.months[month - 1].toLowerCase();
    console.log(month_name);

    let month_str = month.toString();
    if (month_str.length < 2) {
      month_str = "0" + month_str;
    }
    let day_str = day.toString();
    if (day_str.length < 2) {
      day_str = "0" + day_str;
    }
    const date_id = "" + year + month_str + day_str;

    Game.puzzle.id = "wsj" + date_id;
    Game.puzzle.date_string = date_id;

    const url = "https://www.wsj.com/news/puzzle";

    const t0 = performance.now();

    // looking for json link like:
    // https://s3.amazonaws.com/djcs-prod/public/blogs/puzzles/crossword/20201127/47439/data.json

    const index_cb = function (d: string) {
      console.log("index load");

      // links like https://www.wsj.com/articles/fresh-start-friday-crossword-november-6-11604516400
      const pattern = "https://www.wsj.com/articles/[a-z|0-9|-]+?-crossword-" + month_name + "-" + day + "-.+?>";
      const re = new RegExp(pattern);
      const matches = d.match(re);
      if (matches && matches.length > 0) {
        const match = matches[0];
        let url = match.replace("\">", "");
        url = url.replace('"', "");
        CORS.load(url, page_cb);
      } else {
        console.log("Can't find puzzle link: ", pattern);
      }
    }

    const page_cb = function (d: string) {
      console.log("page load");

      // look for the pdf link:
      // https://s3.amazonaws.com/djcs-prod/public/blogs/puzzles/crossword/20201128/47440/crossword-20201128-47440.pdf

      const pattern = "https://s3.amazonaws.com/djcs-prod/public/blogs/puzzles/crossword/[0-9]+" + ".+?.pdf";
      const re = new RegExp(pattern);
      const matches = d.match(re);
      if (matches && matches.length > 0) {
        const m = matches[0];
        const parts = m.split("-");
        const date = parts[2];
        const id = parts[3].replace(".pdf", "");

        let url = "https://www.wsj.com/puzzles/crossword/" + date + "/" + id + "/data.json";
        CORS.load(url, data_cb);
      } else {
        console.log("Can't find data: ", pattern);
      }
    }

    const data_cb = function (d: string) {
      console.log("data load");
      WSJ.parse(d);
      cb();
      console.log("load: " + (performance.now() - t0) + " ms");
    }

    CORS.load(url, index_cb);
  }

  static parse(response: string) {
    const json = JSON.parse(response);

    Game.puzzle.title = json.data.copy.title;
    Game.puzzle.creator = json.data.copy.byline;
    Game.puzzle.width = json.data.copy.gridsize.cols;
    Game.puzzle.height = json.data.copy.gridsize.rows;

    const populate_input_cells = Game.input.cells.length === 0;

    for (let i = 0; i < json.data.grid.length; i++) {
      const row = json.data.grid[i];
      for (let j = 0; j < row.length; j++) {
        const cel = row[j];
        const type = cel.Blank === "" ? "input" : "block";
        const num = cel.Number === "" ? null : cel.Number;
        let cell: PuzzleCell = {
          x: i,
          y: j,
          solution: cel.Letter,
          type: type,
          number: num,
          guess: "",
          show_error: false,
        };
        let input_cell: PuzzleCell = {
          x: i,
          y: j,
          solution: cel.Letter,
          type: type,
          number: num,
          guess: "",
          show_error: false,
        };
        const idx = (i * Game.puzzle.width) + j;
        Game.puzzle.cells[idx] = cell;
        if (populate_input_cells) {
          Game.input.cells.push(input_cell);
        }
      }
    }

    const across_clues = json.data.copy.clues[0].clues;
    const down_clues = json.data.copy.clues[1].clues;
    for (let i = 0; i < across_clues.length; i++) {
      const c = across_clues[i];
      let clue: PuzzleClue = {
        word_id: Number(c.word),
        text: c.clue,
        number: Number(c.number),
      };
      Game.puzzle.across_clues.push(clue);
    }
    for (let i = 0; i < down_clues.length; i++) {
      const c = down_clues[i];
      let clue: PuzzleClue = {
        word_id: Number(c.word),
        text: c.clue,
        number: Number(c.number),
      };
      Game.puzzle.down_clues.push(clue);
    }

    for (const w of json.data.copy.words) {
      const x = String(w.x);
      const y = String(w.y);
      const x_num = Number(x.split("-")[0]);
      const y_num = Number(y.split("-")[0]);
      let word: PuzzleWord = {
        length: 0,
        x: x_num,
        y: y_num,
        id: w.id,
        idx: 0,
      };
      // word ranges listed like "1-6", so add one to get length
      if (x.includes("-")) {
        const x_end_num = Number(x.split("-")[1]);
        word.length = x_end_num - x_num + 1;
        word.idx = Game.puzzle.across_words.length;
        Game.puzzle.across_words.push(word);
      } else {
        const y_end_num = Number(y.split("-")[1]);
        word.length = y_end_num - y_num + 1;
        word.idx = Game.puzzle.down_words.length;
        Game.puzzle.down_words.push(word);
      }
    }
    Game.puzzle.valid = true;

    if (Game.input.selected_word === null) {
      Game.input.selected_word = Game.puzzle.across_words[0];
    }

    console.log(Game.puzzle);
  }

}